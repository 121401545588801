*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins');
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-container {
  background-color: #292D39;
  height: 100vh;
  width: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.card {
  width: 40ch;
  height: 37ch;
  background: #292D39;
  border-radius: 12px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid #292D39;
}

.logo {
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  width: 10rem;
  z-index: 2;
  transition: all 0.75s ease-out;
}
.infoformation {
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoformation h1 {
  font-size: 3rem;
  transition: all 0.75s ease-out;
}