.upload{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(248, 222, 222);
    height: 8rem;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 1rem;
    border: 1px dashed black;
}
.upload-done{
    background-color: rgba(0, 255, 149, 0.678);
}
.item-hover:hover{
    background-color: rgb(212, 241, 250);
    cursor: pointer;
}